import React from 'react';
import Seo from '../components/Seo';

// without this page gatsby fails to redirect to the desired page (config in gatsby-node.js)
const IndexPage = () => (
  <div>
    <Seo />
  </div>
);

export default IndexPage;
